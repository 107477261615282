/* Very standard JS spaghetti code */

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "./SplitText.js";
import Lenis from "lenis";

/* Lenis scroll standard setup */
const lenis = new Lenis({
	wrapper: document.body, // Should help with lag on safari
	wheelEventsTarget: document.body, // Should help with lag on safari
	duration: 1,
});
function raf(time) {
	lenis.raf(time);
	requestAnimationFrame(raf);
}
requestAnimationFrame(raf);
lenis.on("scroll", ScrollTrigger.update);
gsap.ticker.add((time) => {
	lenis.raf(time * 1000);
});
gsap.ticker.lagSmoothing(0);

/* Load functions */
document.addEventListener("DOMContentLoaded", function () {
	handleLazyLoad({lazy: true, timeout: 0.4}); // Recalculates gsap scrollposition after lazyloaded images load
	addImageSizes(); // Add the Sizes value to images based on current size
	addViewCount(); // the whole viewcount add with ajax
	//toggleOverlays();
	if (prefersReducedMotion !== "true") {
		gsapAnimations(); // run animations
		pageFadeTransition(500);
	}
});

// Helper function to trigger clicks on space or enter presses.
function triggerClickOnEnterOrSpace(element) {
	element.addEventListener("keydown", function (event) {
		if (
			(event.key === "Enter" || event.key === " ") &&
			document.activeElement === element
		) {
			event.preventDefault();
			element.click();
		}
	});
}

function toggleOverlays() {
	const hamburgerButton = document.getElementById("hamburgerButton");
	const searchButton = document.getElementById("searchButton");

	hamburgerButton.addEventListener("click", () => {
		takeoverMenu.classList.toggle("hidden");
		backdrop.classList.toggle("hidden");
	});
	backdrop.addEventListener("click", () => {
		if (!searchBox.classList.contains("hidden")) {
			searchBox.classList.add("hidden");
		}
		if (!takeoverMenu.classList.contains("hidden")) {
			takeoverMenu.classList.add("hidden");
		}
		backdrop.classList.add("hidden");
	});
}

/* Basic Page-transisition */
function pageFadeTransition(fadeInMs) {
	const loader = document.getElementById("loader");
	loader.style.transitionDuration = fadeInMs + "ms";

	// Fade out the loader and show the content when the page is fully loaded
	window.onload = function () {
		setTimeout(() => {
			loader.classList.remove("visible");
			setTimeout(() => {
				loader.style.visibility = "hidden";
			}, fadeInMs);
		}, 150);
	};

	document.querySelectorAll("a").forEach((link) => {
		if (
			link.hostname === window.location.hostname &&
			!link.hash &&
			!link.hasAttribute("download")
		) {
			link.addEventListener("click", function (event) {
				event.preventDefault();

				// Fade the loader back in before navigating away
				loader.style.visibility = "visible";
				loader.classList.add("visible");

				setTimeout(() => {
					window.location = this.href;
				}, fadeInMs); // Match this to the CSS transition duration
			});
		}
	});
}

/* Add sizes="" value to all images for correct image selection*/
function addImageSizes() {
	const images = document.querySelectorAll("picture img");
	if (images.length > 0) {
		images.forEach((img) => {
			const displayedWidth = img.clientWidth;
			img.setAttribute("sizes", `${displayedWidth}px`);
		});
	}
}

let resizeTimeout;
window.addEventListener("resize", () => {
	clearTimeout(resizeTimeout);
	resizeTimeout = setTimeout(addImageSizes, 200); // Adjust timeout as needed
});

/* Fixes loading=lazy layout-shifts for GSAP triggers */
function handleLazyLoad(config = {}) {
	let lazyImages = gsap.utils.toArray("img[loading='lazy']"),
		timeout = gsap
			.delayedCall(config.timeout || 1, ScrollTrigger.refresh)
			.pause(),
		lazyMode = config.lazy !== false,
		imgLoaded = lazyImages.length,
		onImgLoad = () =>
			lazyMode
				? timeout.restart(true)
				: --imgLoaded || ScrollTrigger.refresh();
	lazyImages.forEach((img, i) => {
		lazyMode || (img.loading = "eager");
		img.naturalWidth
			? onImgLoad()
			: img.addEventListener("load", onImgLoad);
	});
}
// usage: you can optionally set lazy to false to change all images to load="eager". timeout is how many seconds it throttles the loading events that call ScrollTrigger.refresh()
//handleLazyLoad({lazy: true, timeout: 0.4});

const prefersReducedMotion = window.matchMedia(
	"(prefers-reduced-motion: reduce)"
).matches;

function gsapAnimations() {
	gsap.registerPlugin(ScrollTrigger, SplitText);

	/* Split text */
	function setupSplits() {
		const targets = gsap.utils.toArray("h2, h3, h4");

		targets.forEach((target) => {
			(mySplitText = new SplitText(target, {type: "words"})),
				(mySplitText2 = new SplitText(target, {
					type: "lines",
					linesClass: "overflow-hidden",
				})),
				(lines = mySplitText.words); //an array of all the divs that wrap each character

			gsap.from(lines, {
				duration: 1.4,
				opacity: 0,
				rotation: 25,
				delay: 0.3,
				y: "1.4em",
				ease: "expo.out",
				stagger: 0.15,
				scrollTrigger: {
					trigger: target,
					start: "top 90%",
					end: "bottom 10%",
					toggleActions: "play none play none",
				},
			});
		});
	}
	setupSplits();
}

function addViewCount() {
	var viewCountBtn = document.getElementById("view-count-btn");

	if (viewCountBtn) {
		viewCountBtn.addEventListener("click", function () {
			fetch(view_count_data.ajax_url + "?action=increment_view_count", {
				method: "POST",
				credentials: "same-origin",
				headers: {
					"Content-Type": "application/x-www-form-urlencoded",
				},
				body: "nonce=" + view_count_data.nonce,
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.success) {
						// Update the count displayed on the page
						var countElement =
							document.getElementById("view-count-number");
						if (countElement) {
							var currentCount =
								parseInt(countElement.textContent) || 0;
							countElement.textContent = currentCount + 1;
						}
						alert("Thank you! Your view has been counted.");
					} else if (data.data === "Already counted today") {
						alert(
							"You've already registered your view today. Come back tomorrow!"
						);
					} else {
						alert("There was an issue recording your view.");
					}
				});
		});
	}

	// Fetch and display the initial view count
	function fetchViewCount() {
		fetch(view_count_data.ajax_url + "?action=get_view_count")
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					var countElement =
						document.getElementById("view-count-number");
					if (countElement) {
						countElement.textContent = data.data.count;
					}
				}
			});
	}
	fetchViewCount();
}
